import React from "react";
import styled from "styled-components";
import { TbBrandTiktok, TbBrandInstagram, TbBrandLinkedin, TbMail } from "react-icons/tb";

const socialMedias = [
  { icon: TbMail, href: "mailto:media.tmoy@gmail.com" },
  { icon: TbBrandInstagram, href: "https://www.instagram.com/tmoy_media/" },
  { icon: TbBrandTiktok, href: "https://www.tiktok.com/@taylor_moy" },
  { icon: TbBrandLinkedin, href: "https://www.linkedin.com/in/taylormoy" },
];

const SocialMedia = () => (
  <SocialMediaContainer>
    {socialMedias.map((socialMedia, i) => (
      <a target="_blank" rel="noopener noreferrer" href={socialMedia.href} key={`${socialMedia.icon.toString()}-${i}`}>
        <socialMedia.icon size="24px" className="icon" />
      </a>
    ))}
  </SocialMediaContainer>
);

export default SocialMedia;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;

  .icon {
    margin-right: 10px;
    transition: 0.2s;

    :hover {
      transform: scale(1.2);
    }
  }
`;
