import React, { useState } from "react";
import styled from "styled-components";
import logoImg from "../images/lgLogo.png";
import { Link } from "gatsby";

const links = [
  {name: 'Home', to: ''},
  {name: 'About', to: 'about'},
  {name: 'Portfolio', to: 'portfolio'},
  {name: 'Contact', to: 'contact'},
]

export default function Navbar() {
  const [isOpen, setBurger] = useState(false);

  return (
    <>
      <HamburgerContainer onClick={() => setBurger(!isOpen)}>
        <Hamburger animate={isOpen} />
      </HamburgerContainer>

      <NavContainer animate={isOpen}>
        <Logo src={logoImg} title="logo" />

        <Nav>
          {links.map((link) => (
            <li key={link.name}>
              <Link to={`/${link.to}`} activeClassName="active" onClick={() => setBurger(!isOpen)}>
                {link.name}
              </Link>
            </li>
          ))}
        </Nav>
      </NavContainer>
    </>
  );
}

const NavContainer = styled.div`
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
  padding: 20px 5px 0 0;
  background-color: #fff;
  border-bottom: ${(props) => props.theme.text} 4px solid;
  padding-bottom: 10px;
  transition: transform 0.4s;
  z-index: 50;

  @media screen and (max-width: 600px) {
    position: fixed;
    height: 100vh;
    transform: ${(props) => (props.animate ? "translateX(0)" : "translateX(120%)")};
  }
`;

const Logo = styled.img`
  height: 80px;

  @media screen and (max-width: 600px) {
    padding: 10px 0;
  }
`;

const Nav = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
  border-top: ${(props) => props.theme.secondary} 1px solid;
  border-bottom: ${(props) => props.theme.secondary} 1px solid;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    border: none;
  }

  li {
    font-weight: bold;
    font-style: normal;
    font-size: 11pt;
    padding: 10px 0 10px 2rem;

    a {
      text-decoration: none;
      transition: color 0.4s;

      :hover {
        color: ${(props) => props.theme.accent};
      }
    }

    @media screen and (max-width: 600px) {
      width: 60%;
      padding: 15px 0;
      border-bottom: ${(props) => props.theme.secondary} 1px solid;

      :nth-last-child(1) {
        border: none;
      }
    }
  }
`;

const HamburgerContainer = styled.div`
  @media screen and (max-width: 600px) {
    content: "";
    position: absolute;
    right: 10px;
    top: 10px;
    height: 60px;
    width: 60px;
    z-index: 51;
  }
`;

const Hamburger = styled.div`
  @media screen and (max-width: 600px) {
    display: inline-block;
    margin: 25px 15px;
    width: 30px;
    height: 5px;
    background-color: ${props => props.animate ? '#ffffff00' : props.theme.secondary};
    transition-property: transform;
    transition-duration: 0.4s;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 30px;
      height: 5px;
      position: ${props => props.animate ? 'fixed' : 'absolute'};
      background: ${props => props.theme.blue};
      transition-property: margin, transform;
      transition-duration: 0.2s;
    }

    &:before {
      margin-top: ${(props) => (props.animate ? "0" : "-10px")};
      transform: ${(props) => (props.animate ? "rotate(45deg)" : "none")};
    }

    &:after {
      margin-top: ${(props) => (props.animate ? "0" : "10px")};
      transform: ${(props) => (props.animate ? "rotate(-45deg)" : "none")};
    }
  }

  .responsive {
    .icon {
      background-color: #ffffff00;
    }
  }
`;
