import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { TbPlayerPlay } from "react-icons/tb";
import { InstagramEmbed } from "react-social-media-embed";
import styled from "styled-components";

const Client = ({client}) => (
  <ClientContainer>
    <h2>
      <a target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/${client.name.slice(1)}/`}>
        {client.name}
      </a>
    </h2>
    <p>{client.description}</p>
    <span />

    <ImageContainer>
      {
        client.links.map((content, i) =>{
          return !content.image
          ? <InstagramEmbed url={content.link} key={content.link + i}/>
          : <a href={content.link} target="_blank" rel="noopener noreferrer" key={content.link + i}>
            <div className="icon">
              <TbPlayerPlay />
              <GatsbyImage image={getImage(content.image)} alt="" />
            </div>
          </a>
        })
      }
    </ImageContainer>
  </ClientContainer>
);

export default Client;

const ClientContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 1rem;
  width: 40%;
  border: ${props => `2px solid ${props.theme.lightblue}`};
  background-color: ${props => props.theme.bg};
  border-radius: 1rem;
  padding: 2rem 1rem;

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  :before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    left: 10px;
    background-color: ${props => props.theme.lightblue};
    border-radius: 1rem;
    z-index: -1;
  }

  img {
    width: 100%;
  }

  h2>a {
    color: ${props => props.theme.heading};
    transition: .3s;

    :hover {
      color: ${props => props.theme.accent};
      letter-spacing: .75px;
    }
  }

  p {
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;
  }

  span {
    width: 60%;
    height: 2px;
    background-color: ${props => props.theme.accent};
    margin-bottom: 2rem;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 400px;
  overflow-y: auto;

  a {
    flex: 50%;
  }

  .icon {
    position: relative;
    border: 3px dotted ${props => props.theme.bg};
    transition: .2s;

    svg {
      position: absolute;
      transform: scale(0);
      transition: transform .3s;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      z-index: 5;
      stroke: ${props => props.theme.accent};
      fill: ${props => props.theme.accent}
    }

    :hover {
      svg {
        transform: scale(5);
      }

      border-color: ${props => props.theme.accent};
      border-radius: 5%;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.secondary};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.accent};
  }
`