import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { TbChevronsRight, TbChevronsLeft } from "react-icons/tb"

const Carousel = ({ photos, idx, setIdx }) => {

  const carouselJump = (i) => {
    setIdx(i)
    if (i === 0) {
      document.getElementById("carousel").style.transform = 'translate(0)'
      return
    }
    
    const maxTranslate = photos.length * 154 - document.getElementById("carouselContainer").offsetWidth
    const toTranslate = i * 154 - 50

    document.getElementById("carousel").style.transform =
    `translate(-${toTranslate >= maxTranslate ? maxTranslate : toTranslate}px)`
  }

  const jumpRight = () => {
    const currTranslate = parseInt(document.getElementById("carousel").style.transform.replace(/\D/g, ""))
    const maxTranslate = photos.length * 154 - document.getElementById("carouselContainer").offsetWidth
    const toTranslate = document.getElementById("carouselContainer").offsetWidth + (currTranslate || 0)

    document.getElementById("carousel").style.transform =
      `translate(-${toTranslate >= maxTranslate ? maxTranslate : toTranslate}px)`
  }

  const jumpLeft = () => {
    const currTranslate = document.getElementById("carousel").style.transform.replace(/\D/g, "")
    const minTranslate = 0
    const toTranslate = (currTranslate || 0) - document.getElementById("carouselContainer").offsetWidth

    if (currTranslate) {
      document.getElementById("carousel").style.transform =
      `translate(-${toTranslate <= minTranslate ? minTranslate : toTranslate}px)`
    }
  }

  const toLeft = () => {
    if (idx > 0) {
      carouselJump(idx-1)
    } else {
      carouselJump(photos.length-1)
    }
  }

  const toRight = () => {
    if (idx < photos.length - 1) {
      carouselJump(idx+1)
    } else {
      carouselJump(0)
    }
  }
  
  return (
    <Container>
      <Slide>
        <span className="left" onClick={() => toLeft()}>
          <TbChevronsLeft size="36px" />
        </span>

        <GatsbyImage image={getImage(photos[idx].node)} alt="" />

        <span className="right" onClick={() => toRight()}>
          <TbChevronsRight size="36px" />
        </span>
      </Slide>

      <CarouselOuter id="carouselContainer">
        <span className="left" onClick={() => jumpLeft()}>
          <TbChevronsLeft size="24px" />
        </span>
        <CarouselThumbnails>
          <CarouselContainer id="carousel">
            {photos.map((photo, i) => (
              <div 
                className="item" 
                key={photo.node.id}
                onClick={() => carouselJump(i)}>
                <GatsbyImage image={getImage(photo.node)} alt="" />
              </div>
            ))}
          </CarouselContainer>
        </CarouselThumbnails>

        <span className="right" onClick={() => jumpRight()}>
          <TbChevronsRight size="24px" />
        </span>
      </CarouselOuter>
    </ Container>
)};

export default Carousel;

const Container = styled.div`
  width: 80vw;
  max-width: 1000px;
  margin-bottom: 2rem;

  @media screen and (max-width: 600px) {
    width: 95vw;
  }

  span {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 3rem;
    height: 101%;
    cursor: pointer;

    svg {
      stroke: ${props => props.theme.bg };
      position: absolute;
      top: calc(50% - 12px);
      background-color: ${props => `${props.theme.text}99`};
      border-radius: 50%;
      transition: opacity .6s;
    }

    :hover > svg {
      opacity: .8;
    }
  }

  span.left {
    left: -10px;

    svg {
      left: 5px;
    }
  }

  span.right {
    right: -10px;

    svg {
      right: 5px;
    }
  }
`

const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 550px;
  transform: 0px;
  text-align: center;

  img {
    vertical-align: middle;
    max-height: 550px;
    width: fit-content;
  }

  @media screen and (max-width: 600px) {
    height: 400px;

    img {
      max-height: 400px;
    }
  }
`

// Carousel List Bottom
const CarouselOuter = styled.div`
  position: relative;
  margin-top: 10px;
`

const CarouselThumbnails = styled.div`
  position: relative;
  overflow: hidden;
`

const CarouselContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: fit-content;
  transition: .5s;

  .item {
    display: flex;
    align-items: stretch;
    height: 90px;
    width: 150px;
    margin: 2px;
    transition: .2s;
    transform: 0;
    cursor: pointer;

    :hover {
      opacity: .6;
    }

    img {
      top: initial;
    }
  }
`