import React from "react";
import styled from "styled-components";

const Heading = ({title, cursive}) => (
  <Container>
    <h1>
      {title}
      <span>{cursive}</span>
    </h1>
  </Container>
);

export default Heading;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  width: 100vw;
  background-color: ${props => props.theme.text};
  background-image: url("/images/city.jpg");
  background-size: 100%;
  background-position: center;
  margin-bottom: 2rem;
  margin-top: -2rem;
  z-index: -2;

  h1 {
    position: relative;
    left: -5%;
    color: ${props => props.theme.bg};
    padding-bottom: 1.5rem;

    span {
      position: absolute;
      font-family: "pacifico";
      color: ${props => props.theme.blue};
      left: calc(60%);
      padding: 8px;
      width: 200%;
      z-index: -1;
      font-weight: normal;
    }
  }
`