import React from "react";
import styled from "styled-components";
import SocialMedia from "./socialMedia";

const Contact = () => (
  <ContactContainer>
    <form name="contact" data-netlify="true" netlify-honeypot="bot-field" method="POST">
      <input type="hidden" name="form-name" value="contact" />
      
      <label htmlFor="name">Full Name
        <input type="text" name="name" placeholder="First Last" required />
      </label>
      <label htmlFor="email">Email Address
        <input name="email" type="email" placeholder="your@email.com" required />
      </label>
      <label htmlFor="message">Message
        <textarea name="message" placeholder="your message..." required />
      </label>
      <button type="submit">Get in touch</button>
    </form>

    <div className="infoContainer">
      <div className="infoPair">
        <h3>Contact</h3>
        <a href="mailto:media.tmoy@gmail.com">media.tmoy@gmail.com</a>
      </div>
      <SocialMedia />
    </div>
  </ContactContainer>
);

export default Contact;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 600px;
  width: 90%;
  border: ${(props) => props.theme.text} solid 3px;
  padding: 5%;
  margin-bottom: 2rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 95%;
  }

  label {
    font-size: 16px;
    color: ${(props) => props.theme.heading};

    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding-right: 20px;
    width: 100%;

    @media screen and (max-width: 600px) {
      min-width: 0;
      padding: 0;
      align-items: center;
    }
  }

  button {
    @media screen and (max-width: 600px) {
      width: 95%;
    }
  }

  h3 {
    font-size: 18px;
    padding: 0px;
    color: ${(props) => props.theme.heading};
    margin: 0 0 10px 0;
  }

  p {
    padding: 0px;
    margin: 0px;
    font-size: 16px;
  }

  a {
    font-size: 16px;
  }

  input {
    margin: 10px 0;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: ${(props) => props.theme.text} solid 1.5px;
    width: 90%;
    font-size: 14px;
    margin-bottom: 20px;

    @media screen and (max-width: 600px) {
      text-align: center;
      width: 80%;
    }
  }

  textarea {
    width: 92%;
    margin: 10px 0;
    height: 150px;
    padding: 12px;
    box-sizing: border-box;
    border: none;
    outline: none;
    border: 1px solid ${(props) => props.theme.text};
    background: transparent;
    border-radius: 4px;
    font-size: 14px;

    @media screen and (max-width: 600px) {
      ::placeholder {
        text-align: center;
      }
    }
  }

  img {
    width: 120px;
  }

  .imageSpacing {
    margin-top: 20px;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    padding-left: 20px;

    @media screen and (max-width: 600px) {
      padding-left: 0;
      padding-top: 20px;
      align-items: center;
    }
  }

  .infoPair {
    margin: 10px 0;

    @media screen and (max-width: 600px) {
      text-align: center;
    }
  }
`;
