import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "../styles";
import { Navbar, Footer } from "./";

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Navbar />
      <Padding>{children}</Padding>
      <Footer />
    </ThemeProvider>
  );
}

const Padding = styled.div`
  padding-bottom: 50px;
`;
