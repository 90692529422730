const theme = {
  bg: '#ffffff',
  secondary: '#eed3b8',
  lightblue: '#d4e4ee',
  blue: '#9ab6c7',
  accent: '#e9ae62',
  heading: '#272229',
  text: '#58535b'
}

export default theme