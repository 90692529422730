import React from "react";
import styled from "styled-components";
import SocialMedia from "./socialMedia";

const Footer = () => (
  <FooterContainer>
    <footer>
      © {new Date().getFullYear()} Taylor Moy <span className="emoji" /> <span className="divider" />
      <SocialMedia />
    </footer>
  </FooterContainer>
);

export default Footer;

const FooterContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100vw;
  padding: 15px 0;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.text};

  footer {
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: default;
    color: ${(props) => props.theme.lightblue};

    svg {
      stroke: ${props => props.theme.lightblue};
    }
  }

  .emoji {
    font-size: 18px;
  }

  .emoji:after {
    content: "📷";
  }

  .emoji:hover:after {
    content: "📸";
  }

  .divider {
    border-left: ${(props) => props.theme.secondary} 2px solid;
    height: 24px;
    padding-right: 3px;
  }

  span {
    margin: 0 5px;
  }
`;
