import React from "react";
import styled from "styled-components";
import { Client } from "./"

const Wedding = () => (
  <WeddingContainer>
    <a class='button' href='#eventinquiry'>Inquire</a>
		<About>
			<p>
				<b class="question"><span>WHAT</span> does a wedding content creator do?</b>
				<ul><li><b>TLDR (too long didn't read)</b>: I capture short form video and photos for you to reminisce on and to quickly post to social media the next morning. Content is delivered within 24 hours. Want a specific reel or TikTok recreated? I gotchu.
				</li>
				<li><b>Long Answer</b>: While you wait for your beautifully edited long form video and professional photos, you can remember your wedding through the content I capture on my iPhone. Yes... iPhone... The point is, to be able to seamlessly follow you behind the scenes to catch the impromptu moments (hugs, laughs, weird quirks, you name it). The best part is, you'll be able to get everything within 24 hours via Dropbox.
				</li></ul>
				<b class="question"><span>WHY</span> should I consider a content creator?</b>
				<ul><li><b>You want a phoneless wedding.</b> You've seen nightmare photos of guests holding their phones up throughout the ceremony. You can ensure your media team are the only ones.
				</li>
				<li><b>You're so busy getting married, you don't get to see your masterful planning come to fruition.</b> I'll capture all the little details you may not get to see (personalized drinks, cocktail hour, floral setup, etc.)
				</li></ul>
			</p>
			<Client client={{
				name: '@contentbytmoy',
				description: 'portfolio of wedding content',
				links: [{
					image: '',
					link: 'https://www.instagram.com/contentbytmoy/'
				}]
			}}/>
			</About>
			<Heading>
				<h2>Packages</h2>
			</Heading>
			<p><b>All packages include:</b>
			<ul>
				<li>All photo and video content delivered within 24 hours via Dropbox.</li>
				<li>1 pre-event consultation and questionnaire.</li>
			</ul></p>

			<Packages>
			<ImageContainer>
				<h3>Package 1 <span>$750</span></h3>
				<ul>
					<li>10 hours of on-site coverage</li>
					<li>1 fully edited 9x16 recap video (:60 or less)</li>
				</ul>
			</ImageContainer>
			<ImageContainer>
				<h3>Package 2 <span>$600</span></h3>
				<ul>
					<li>8 hours of on-site coverage</li>
					<li>1 fully edited 9x16 recap video (:60 or less)</li>
				</ul>
			</ImageContainer>
			<ImageContainer>
				<h3>Package 3 <span>$450</span></h3>
				<ul>
					<li>6 hours of on-site coverage</li>
					<li>1 fully edited 9x16 recap video (:30 or less)</li>
				</ul>
			</ImageContainer>
			</Packages>
			<Packages>
				<ImageContainer>
					<h3>Custom Package</h3>
					<ul class='size'>
						Don’t see what you’re looking for? Let’s chat and create a custom package!
					</ul>
				</ImageContainer>
				<ImageContainer>
					<h3>Add-Ons</h3>
					<ul class='size'>
						<li>Additional hours of coverage $75/hour</li>
						<li>Additional TikToks/Reels $50/video</li>
						<li>Other events: Proposals, tea ceremony, engagement party, welcome event, rehearsal dinner, etc. $75/hour</li>
					</ul>
				</ImageContainer>
			</Packages>
			
			<Heading>
				<h2 id="eventinquiry">Wedding/Event Inquiry</h2>
			</Heading>

			<form method="post" netlify-honeypot="bot-field" data-netlify="true" name="wedding">
				<input type="hidden" name="bot-field" />
		   	<input type="hidden" name="form-name" value="wedding" required/>

				<label class="col" for="weddingemail">Email Address
					<input type="weddingemail" name="email" id="weddingemail" placeholder="your@email.com" required/>
				</label>

				<div class="row">
					<label class="col" for="weddingfname">Your Name
						<input type="text" name="weddingfname" id="weddingfname" placeholder="First Name" required/>
					</label>

					<label class="col" for="weddinglname">
						<input type="text" name="weddinglname" id="weddinglname" placeholder="Last Name" required/>
					</label>
				</div>

				<div class="row">
					<label class="col" for="weddingpfname">Partner's Name
							<input type="text" name="weddingpfname" id="weddingpfname" placeholder="First Name" required/>
					</label>

					<label class="col" for="weddingplname">
						<input type="text" name="weddingplname" id="weddingplname" placeholder="Last Name" required/>
					</label>
				</div>

				<label class="col" for="weddingeventdate">Event Date
					<input type="date" name="weddingeventdate" id="weddingeventdate" required/>
				</label>
				
				<label class="col" for="weddingaddress1">
					Event Location
					<textarea type="text" name="weddingaddress1" id="weddingaddress1" placeholder="venue name and address" required/>
				</label>

				<label class="col" for="weddingeventtype">Type of Event
					<input type="text" name="weddingeventtype" id="weddingeventtype" placeholder="wedding, conference, bachelor party, etc" required/>
				</label>

				<fieldset class="col">
					<legend><b>Preferred Package</b></legend>
					<label class="nb m5">
						<input type="checkbox" name="package" value="Package 1 (10 hours)"/>
						Package 1 (10 hours)
					</label>

					<label class="nb m5">
						<input type="checkbox" name="package" value="Package 2 (8 hours)"/>
						Package 2 (8 hours)
					</label>

					<label class="nb m5">
						<input type="checkbox" name="package" value="Package 3 (6 hours)"/>
						Package 3 (6 hours)
					</label>

					<label class="nb m5">
						<input type="checkbox" name="package" value="Custom Package"/>
						Custom
					</label>
					<label class="nb m5">
						<input type="checkbox" name="package" value="Let's chat/Not sure"/>
						Let's chat/Not sure
					</label>
				</fieldset>

				<label class="col" for="weddingadditional">Additional Details
					<textarea name="weddingadditional" id="weddingadditional" 
					placeholder="why would you like a content creator? what questions do you have? what are you looking for - any specific content?"
					rows="10" required/>
				</label>

				<label class="col" for="weddinghearabout[]">How did you hear about me?
					<select name="weddinghearabout[]" id="weddinghearabout[]">
						<option value="instagram">Instagram</option>
						<option value="facebook">Facebook</option>
						<option value="google">Google</option>
						<option value="vendor">Vendor Referral</option>
						<option value="client">Client Referral</option>
						<option value="family/friend">Family/Friend Referral</option>
						<option value="other">Other</option>
					</select>
				</label>

				<button type="submit">Get in touch</button>
			</form>
  </WeddingContainer>
);

export default Wedding;

const WeddingContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
	align-items: center;
  width: 90%;
  padding: 0 5%;
  margin-bottom: 2rem;
	margin-top: -30px;

	span {
		font-size: 15pt;
	}

	.button {
		font-size: 15pt;
		font-weight: bold;
		background-color: ${props => props.theme.accent};
		padding: 15px 35px;
		border-radius: 20px;
		text-decoration: none;
		transition: .5s;
	}
	.button:hover {
		padding: 15px 45px;
		color: ${props => props.theme.bg};
	}

	form {
		display: flex;
		flex-direction: column;
		max-width: 600px;

		label {
			font-weight: bold;
			margin-bottom: 20px;
		}

		.nb {
			font-weight: normal;
		}

		input {
			padding: 10px 5px;
		}

		fieldset {
			margin-bottom: 20px;
		}

		.m5 {
			margin-bottom: 4px;
		}

		.row {
			display: flex;
			flex-direction: row;
			column-gap: 15px;
			align-items: flex-end;

		}

		.col {
			display: flex;
			flex-direction: column;
			
		}
	}

	@media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
		padding: 0 10px;

		form .row {
			display: flex;
			flex-direction: column;
		}
  }
`;

const About = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 10px;

	.question {
		position: relative;
		font-size: 13pt;

		::before {
			position: absolute;
			content: '';
			width: 205px;
			height: 2px;
			bottom: -4px;
			left: -7px;
			border-bottom: 4px solid ${props => props.theme.accent};
			z-index: -1;
		}
	}

	li {
		padding-bottom: 17px;
	}

	@media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;

		ul {
			margin-left: -12px;
		}
	}
`

const Packages = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 10px;

	@media screen and (max-width: 600px) {
		flex-direction: column;
		width: 100%;
	}
`

const ImageContainer = styled.div`
  position: relative;
  margin: 12px 30px;
  transition: .3s;
	max-width: 300px;

	@media screen and (max-width: 600px) {
		margin: 12px 30px 0 10px;;
	}

  h3 {
    display: block;
    width: 60%;
    padding: 10px;
    margin: 0 0 -18px -20px;
    color: ${props => props.theme.bg};
    background-color: ${props => props.theme.text};
    border-radius: 0 15px;
    transition: .3s;

		@media screen and (max-width: 600px) {
			flex-direction: column;
			width: 80%;
		}
  }

  ul {
		display: flex;
		flex-direction: column;
		justify-content: center;
    width: 240px;
		height: 100%;
		padding: 20px 18px;
    border: ${props => props.theme.text} solid 3px;
    box-shadow: 10px 10px ${props => props.theme.lightblue};
    background-color: ${props => props.theme.bg};
    transition: .3s;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

	li {
		margin-left: 10px;
		padding: 5px 0;
	}

	.size {
		height: 200px;

		@media screen and (max-width: 600px) {
      height: auto;
    }
	}

	span {
		color: ${props => props.theme.lightblue};
		font-size: 12pt;
		padding-left: 10px;
	}

  :before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 25px;
    left: -10px;
    height: 75%;
    width: 100%;
    border: ${props => props.theme.lightblue} solid 3px;
    transition: .3s;

		@media screen and (max-width: 600px) {
			width: 110%;
		}
  }
`
const Heading = styled.div`
	text-align: center;
  padding: 4rem 0;
  width: 100vw;
  background-color: ${props => props.theme.text};
  background-image: url("/images/city.jpg");
  background-size: 100%;
  background-position: center;
  margin-bottom: 2rem;
	margin-top: 4rem;
  z-index: -2;

  h2 {
    position: relative;
		letter-spacing: 1px;
		font-size: 25pt;
    color: ${props => props.theme.bg};
    padding: 0;
		margin: 0;
  }
`