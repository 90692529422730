import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { Client } from "../components";

const buttons = [
  { name: "Freelance Clients" },
  { name: "P&N Lawyers" },
  { name: "Ryse Network" },
  { name: "Humans of Disney" },
];

const SocialMedia = () => (
  <StaticQuery 
    query={graphql`
      query SocialMediaClientsQuery {
        allSocialMediaClientsJson {
          edges {
            node {
              name
              description
              links {
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                link
              }
            }
          }
        }
      }
    `}
    render={data => (
      <SocialMediaContainer>
        {/* <ButtonsContainer>
          {buttons.map(button => (
            <button key={button.name}>
              {button.name}
            </button>
          ))}
        </ButtonsContainer> */}
        <ClientContainer>
          {data.allSocialMediaClientsJson.edges.map(client => (
            <Client client={client.node} key={client.name} />
          ))}
        </ClientContainer>
        
      </SocialMediaContainer>
    )}
  />
);

export default SocialMedia;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6px;
  justify-content: center;
`

const ClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 6px;
  justify-content: center;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  button {
    position: relative;
    border-radius: 10px;
    margin: 5px;
    background-color: ${props => props.theme.bg};
    border: 2px solid ${props => props.theme.lightblue};
    font-weight: bold;
    
    :hover {
      padding: 13px 0;
      border: 3px solid ${props => props.theme.secondary};
      letter-spacing: .75px;
      color: ${props => props.theme.accent};
    }
  }
`;
