import React from "react";
import styled from "styled-components";
import { GraphicThumbnail, VideoThumbnail, PhotoThumbnail, SocialThumbnail, WeddingThumbnail } from "../images/portfolio";
import { Link } from "gatsby";

const links = [
  {title: "Video Production", image: VideoThumbnail, link: "videos"},
  {title: "Social Media", image: SocialThumbnail, link: "socialmedia"},
  {title: "Graphics", image: GraphicThumbnail, link: "graphics"},
  {title: "Photography", image: PhotoThumbnail, link: "photos"},
  {title: "Weddings/Events", image: WeddingThumbnail, link: "weddings"}
]

const PortfolioNav = () => (
  <Container>
    {links.map(link => (
      <Link to={`/portfolio/${link.link}`} key={`p${link.title}`}>
        <ImageContainer>
          <h3>{link.title}</h3>
          <img src={link.image} alt="" />
        </ImageContainer>
      </Link>
    ))}
  </Container>
)

export default PortfolioNav

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;

  a {
    text-decoration: none;
  }
`

const ImageContainer = styled.div`
  position: relative;
  margin: 15px;
  transition: .3s;

  :hover {
    transform: scale(1.05);

    h3 {
      background-color: ${props => props.theme.heading};
    }

    img {
      box-shadow: 15px 15px ${props => props.theme.accent};
      border: ${props => props.theme.heading} solid 3px;
    }

    :before {
      border: ${props => props.theme.secondary} solid 3px;
    }
  }

  h3 {
    display: block;
    width: 60%;
    padding: 10px;
    margin: 0 0 -10px -15px;
    color: ${props => props.theme.bg};
    background-color: ${props => props.theme.text};
    border-radius: 0 15px;
    transition: .3s;
  }

  img {
    width: 290px;
    min-height: 206px;
    border: ${props => props.theme.text} solid 3px;
    box-shadow: 10px 10px ${props => props.theme.lightblue};
    background-color: ${props => props.theme.lightblue};
    transition: .3s;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  :before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 20px;
    left: -10px;
    height: 82%;
    width: 100%;
    border: ${props => props.theme.lightblue} solid 3px;
    transition: .3s;
  }
`