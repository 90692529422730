import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { TbChevronLeft } from "react-icons/tb";

export default function GoBack() {
  return (
    <Button>
      <Link to="/portfolio">
        <TbChevronLeft size="21px" /> Portfolio
      </Link>
    </Button>
  );
}

const Button = styled.button`
  border-radius: 1rem;
  align-self: flex-start;
  background-color: transparent;
  padding: 10px 0;
  border: 2px solid ${props => props.theme.lightblue};
  font-weight: bold;
  margin: 0 0 2rem;

  a {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  :hover {
    border: 2px solid ${props => props.theme.secondary};
    opacity: .9;
    letter-spacing: 1px;
  }

  svg {
    padding-right: 5px;
  }
`