import React, { useState } from "react";
import styled from "styled-components";
import { TbChevronsLeft, TbChevronsRight } from "react-icons/tb";

const Card = ({ element }) => {
  const [index, setIndex] = useState(0);

  const onClickForward = () => {
    if (element?.length) {
      const newIndex = element.length > index + 1 ? index + 1 : 0;
      setIndex(newIndex);
    }
  };

  const onClickBackward = () => {
    if (element?.length) {
      const newIndex = index - 1 >= 0 ? index - 1 : element.length - 1;
      setIndex(newIndex);
    }
  };

  const cardElement = element?.length ? element[index] : element;
  return (
    <CardContainer backgroundColor={cardElement?.backgroundColor}>
      {element?.length && (
        <span className="left" onClick={onClickBackward}>
          <TbChevronsLeft size="24px" />
        </span>
      )}
      {!!cardElement?.showImage && cardElement.showImage}
      {!!cardElement?.title && <h3>{cardElement.title}</h3>}
      {!!cardElement?.icon &&
        (!cardElement?.download ? (
          <div className={`icon ${!cardElement.title ? "extraPadding" : ""}`}>
            <cardElement.icon size={cardElement?.biggerIcon ? cardElement.biggerIcon : "80px"} />
          </div>
        ) : (
          <a
            href={cardElement.download}
            target="_blank"
            rel="noopener"
            className={`icon ${!cardElement.title ? "extraPadding" : ""}`}
          >
            <cardElement.icon size={cardElement?.biggerIcon ? cardElement.biggerIcon : "80px"} />
          </a>
        ))}
      {cardElement?.superText && <h2>{cardElement.superText}</h2>}
      {cardElement?.text && <p>{cardElement.text}</p>}
      {cardElement?.centeredText && <p className="centered">{cardElement.centeredText}</p>}
      {cardElement?.subtext && (
        <>
          <div className="divider" /> <p className="subtext">{cardElement?.subtext}</p>
        </>
      )}
      {element?.length && (
        <span className="right" onClick={onClickForward}>
          <TbChevronsRight size="24px" />
        </span>
      )}
      <div />
    </CardContainer>
  );
};

export default Card;

const CardContainer = styled.div(
  ({ backgroundColor }) => `
    display: flex;
    flex-direction: column;
    height: 240px;
    width: 240px;
    background-color: ${backgroundColor ? backgroundColor : "#f3f3f3"};
    margin: 10px;
    padding: 2%;
    border-radius: 5px;
    transition: 0.2s;
    justify-content: space-between;
    position: relative;

    h3 {
      margin: 0;
      font-weight: bolder;
    }

    h2 {
      color: #faf4ed; 
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
    }

    p {
      text-align: start !important;
      padding: 0 10%;
      margin: 0;
      line-height: normal;
    }

    .centered {
      text-align: center !important;
      font-size: larger;
    }

    .subtext {
      font-size: smaller;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .extraPadding {
      padding: 30px;
    }

    span {
      position: absolute;
      top: 50%;
      transition: 0.2s;
      cursor: pointer;
      margin: 0 10px;
    }

    span:hover + span {
      transform: scale(1.2);
    }

    .divider {
      width: 80px;
      margin-left: 20px;
      margin-bottom: 8px;
      border-bottom:  #eed3b8 3px solid;
      border-radius: 20px;
    }

    img {
      border-radius: 5px;
      transform: scale(1.1);
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
    }
  )`
);
