import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";
import "@fontsource/pacifico";

export default createGlobalStyle`
  html {
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  
  * {
    color: ${(props) => props.theme.text};
    font-family: 'montserrat';
  }

  body {
    position: relative;
    background-color: ${(props) => props.theme.bg};
    overflow-x: hidden;
    margin: 0;
    padding-top: 150px;
    min-height: calc(100vh - 150px);

    @media screen and (max-width: 600px) {
      padding-top: 0;
      min-height: 100vh;
    }
  }
  
  h1 {
    color: ${(props) => props.theme.heading};
    font-size: 28pt;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .active {
    color: ${(props) => props.theme.accent};
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 2rem;
    max-width: 1200px;
  }

  button {
    overflow: visible;
    border: transparent 1px solid;;
    border-radius: 40px;
    font-size: 18px;
    width: 150px;
    padding: 15px 0px;
    margin: 10px 0px;
    background-color: ${(props) => props.theme.accent};
    transition: 0.2s;
    cursor: pointer;
  }

  button:hover {
    border: ${(props) => props.theme.text} 1px solid;
  }

  p {
    line-height: 24px;
  }

  .center {
    text-align: center;
  }

  `;
