import React from "react";
import styled from "styled-components";
import { TikTokEmbed, InstagramEmbed, YouTubeEmbed } from 'react-social-media-embed';

// for measuring screen-size
// to decide how many columns
// import Measure from 'react-measure';

const Gallery = () => (
  <MediaGallery>
    <Column>
      <YouTubeEmbed url="https://www.youtube.com/watch?v=b9q9AwcvBx8" width='333' height='188' youTubeProps={{modestbranding: '1'}} />
      <InstagramEmbed url="https://www.instagram.com/p/CXXRJ1aLsxK/" />
    </Column>
    <Column>
    <InstagramEmbed url="https://www.instagram.com/tv/Cb-wlf3tZGf" />
      <YouTubeEmbed url="https://www.youtube.com/watch?v=8OFprXSCf6o" width='333' height='188' youTubeProps={{modestbranding: '1'}} />
    </Column>
    <Column>
      <YouTubeEmbed url="https://www.youtube.com/watch?v=PH6XWv5l95A" width='333' height='370' youTubeProps={{modestbranding: '1'}} />
    </Column>
  </MediaGallery>
);

export default Gallery;

const MediaGallery = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`